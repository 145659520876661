module.exports = {
  title: 'Puma Browser',
  beta: 'beta',
  careers: 'Careers',
  privacyBlurb: 'Privacy focused web browser with a new way to pay for content and services without ads and tracking.',
  earlyPreviewBlurb: 'Powered by Coil and Interledger Protocol.',
  signUpBlurb: 'Sign up to stay up to date.',
  iPhone: 'iPhone / iPad',
  android: 'Android',
  signUp: 'Sign Up',
  appStore: `/app-store.svg`,
  gStore: '/google-play.png',
};
